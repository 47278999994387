body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.h-screen {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.z-0 {
  z-index: 0; /* Animasi di bawah konten teks */
}

#three-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.inset-0 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.-z-10 {
  z-index: -10;
}
